<template>
  <section class="bg-layout">
    <header class="bg-auth">
      <nav class="navbar">
        <div class="container-lg position-relative" style="max-width: 1140px;">
          <div class="d-flex w-100">
            <a class="navbar-brand" href="/">
              <img src="../assets/icon.png" alt="Viva" title="Viva Kencana">
            </a>
            <v-spacer></v-spacer>
            <h6 class="white--text p-4" >Kencana</h6>
          </div>          
        </div>
      </nav>
    </header>
    <div class="container" style="max-width: 1140px;">
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
          <v-layout align-center pr-4>
          <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
              <div>
              <strong>{{ snackbar.title }}</strong>
              </div>
              <div>{{ snackbar.text }}</div>
          </v-layout>
          </v-layout>
          <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
          <v-icon>clear</v-icon>
          </v-btn>
      </v-snackbar>
      <div class="row">
        <div class="col-12 col-lg-7 pr-lg-0">
          <v-card class="rounded-l p-0" outlined style="background: inherit !important;border: 1px solid #fff;">
            <v-carousel
              :continuous="true"
              :cycle="cycle"
              :show-arrows="true"
              delimiter-icon="mdi-minus"
              hide-delimiter-background
              height="350"
              hide-delimiters
            >
              <v-carousel-item>
                <v-img class="rounded-l text-center" src="@/assets/businessman-application-human-digital-business.webp" alt="HRIS">
                </v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img class="rounded-l" src="@/assets/hand-using-laptop-computer-with-virtual-screen-document-online-approve-paperless-quality-assurance-erp-management-concept.webp" alt="HRIS">
                </v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img class="rounded-l" src="@/assets/hands-with-laptop-virtual-world-map.webp" alt="HRIS">
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
          <v-card class="rounded-l p-0 mt-3" height="270">
            <v-card-text>
              <v-row>
                <v-col class="col-12" sm="12" md="12">
                  <h6 style="color: #005caa;">Gagal Login Kencana ?</h6>
                  <p>
                    Untuk menghindari hal tersebut kami punya cara nya :
                  </p>
                  <ol>
                    <li>Pastikan Anda sudah mempunyai nomor ID absensi </li>
                    <li>Pastikan nomor ID sudah dipadankan (mapping) dengan username login launcher dan web KENCANA</li>
                    <li>Kalau belum sepadan bisa hub HRD terkait</li>
                    <li>Kalau masih belum bisa, gak perlu segan-segan untuk menghubungi kami</li>
                    <li>Terimakasih</li>
                  </ol> 
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-12 col-lg-5">
          <v-card class="rounded-l p-0" height="634">
            <v-card-text class="p-4">
              <v-row class="mb-4">
                <div class="col-12 mb-3 mb-xl-0">
                  <h4 class="title ma-0">Halo</h4>
                  <h6 class="text-primary font-weight-normal text-nowrap ma-0"> Selamat Datang! </h6>
                </div>
              </v-row>
              <v-row>
                <v-col cols="12" class="mb-0 pb-0">
                  <label class="col-form-label">NIK</label>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="email"
                      dense
                      hint="Please input your username"
                      required
                      class="border-12 ma-0 pa-0"
                      hide-details=""
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mb-0 pb-0">
                  <label class="col-form-label">Password</label>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      dense
                      v-model="password"
                      :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="show ? 'text' : 'password'"
                      @click:append="show = !show"
                      @keyup.enter="submit()"
                      hint="Please input your password"
                      class="border-12"
                      counter
                      hide-details
                      required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="mt-3">
                  <p>Dengan masuk, saya menyetujui <b>Pernyataan Privasi</b> dan <b>Ketentuan Layanan.</b> <b>PT. Viva Kencana Investindo.</b></p>
                </v-col>
                <v-col cols="12">
                  <vue-recaptcha v-show="showRecaptcha" sitekey="6LfaBxgqAAAAAIMgOBwsLxmbRnLAwrPbhJ44TlWQ"
                  secretkey="6LfaBxgqAAAAABP5w_YDFhYMiHRGUZoGHyMTfqUZ"
                  size="normal" 
                  theme="light"
                  hl="us"
                  :loading-timeout="loadingTimeout"
                  @verify="recaptchaVerified"
                  @expire="recaptchaExpired"
                  @fail="recaptchaFailed"
                  @error="recaptchaError"
                  ref="vueRecaptcha"
                  >
                  </vue-recaptcha>
                </v-col>
                <v-col cols="12">
                  <v-btn type="button" block height="3rem" class="rounded-l border-12 mr-12 pl-12 pr-12 pt-2 pb-2" color="primary" @click="submit()">Login</v-btn>
                </v-col>
                <v-col cols="12">
                  <div class="mt-5 text-center">
                    Password anda salah ? Kunjungi <a class="font-weight-bold" href="https://hris.kencana.org/" target="_blank">HRIS</a>
                  </div>
                </v-col>                
                <!-- <section class="alert alert-v2 alert-warning mb-0 mt-5 ">
                  <div class="d-flex w-100 p-2">
                    <div class="mr-2">
                      <v-icon>mdi-information</v-icon>
                    </div>
                    <section class="text-preline">
                      <div>
                        Perbarui
                        <span class="font-weight-semibold">Password anda</span>
                        secara berkala melalui hris untuk keamanan data dan transaksi Anda.
                      </div>
                    </section>
                  </div>
                </section> -->
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <v-slide-group
          show-arrows        
          :multiple="true"
          >
            <v-slide-item
              v-for="(entity, i) in entities"
              :key="i"
              class="ml-1 mr-2 mt-2 mb-2"
            >
              <v-hover
                  v-slot="{ hover }"
                  close-delay="100"
              >
                <v-card outlined :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
                  <v-card-text class="d-flex">
                    <v-icon color="#005caa" class="mr-3">
                      mdi-web
                    </v-icon>
                    <span>{{entity.text}}</span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-slide-item>
          </v-slide-group>
        </div>
      </div>
    </div>
    <footer class="small py-4 bg-transparent mt-5">
      <div class="container" style="max-width: 1140px;">
        <v-row justify="end">
          <div class="col-12 col-lg-auto ml-lg-auto">
            <div class="row mt-3 mt-lg-0 no-gutters justify-content-lg-end">
              <div class="col-12 col-lg-auto text-center"><span class="text-muted mr-lg-2"> © 2021 PT Viva Kencana Investindo, All Rights Reserved. </span></div>
            </div>
          </div>
        </v-row>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
    name: 'AdminLogin',
    components: {
    },
    data(){
        return {
            email: '',
            password: '',
            error: true,
            show: false,
            errors: [],
            loading: false,
            snackbar: {
              color: null,
              icon: null,
              mode: null,
              position: "top",
              text: null,
              timeout: 7500,
              title: null,
              visible: false
            },
            timeout: 7500,
            visibleShift: 'none',
            shift:'1',
            shiftItems: ['1', '2', '3'],
            timeout: 7500,
            cycle: true,
            entities: [
              {text: 'Kepuh Kencana Arum'},
              {text: 'Kencana Maju Bersama'},
              {text: 'Kencana Baja Trada'},
              {text: 'Sunrise Steel'},
              {text: 'Sunrise Mill'},
              {text: 'Sunrise Mall'},
              {text: 'Surya Maju Sukses'},
              {text: 'Tobang'},
              {text: 'Aruna Harja Kajayan'},
              {text: 'AYOLA'},
              {text: 'Gerai Baja Ringan Kepuh'},
              {text: 'GRIYA KUSUMA'},
              {text: 'Magnum Steel'},
              {text: 'MAHA KARYA INDONESIA'},
              {text: 'MEGA KARYA MAKMUR'},
              {text: 'Maju Mapan Makmur'},
              {text: 'Wahana Baskara Jaya'},
              {text: 'Zinium Peduli'},
              {text: 'HENDYMAN SMART TECHNOLOGY'}
            ],
            showRecaptcha: true,
		        loadingTimeout: 30000,
            recaptcha: ''
        }
    },
    mounted(){
      // console.log(process.env.VUE_APP_URL);
      if(process.env.VUE_AYOLA_ONLY ==1){
        this.visibleShift = ''
      } else {
        this.visibleShift = 'none'
      }
      this.$store.dispatch('setOverlay', false)
    },
    methods: {
        recaptchaVerified(response) {
          this.recaptcha = response
        },
        recaptchaExpired() {
            this.$refs.vueRecaptcha.reset();
        },
        recaptchaFailed() {
        },
        recaptchaError(reason) {
            console.log(reason);
        }, 
        clear(){
          this.email = ''
          this.password = ''
          // this.shift = '1'
        },
        async submit(){          
          // && this.recaptcha
          if (this.email && this.password && this.recaptcha ) {
            this.loading = true
            this.$store.dispatch('setOverlay', true)


            let username = this.email;
            let password = this.password;
            let shif = this.shift;
            if(this.visibleShift == 'none'){
              shif = '1'
            }

            this.$store
            .dispatch("loginUser", { username, password, shif })
            .then(() => {
              this.clear()
              this.loading = false
              this.$store.dispatch('setOverlay', false)

              this.$router.push('/admin/dashboard');
              
              this.snackbar = {
                color: "success",
                icon: "mdi-checkbox-marked-circle",
                mode: "multi-line",
                position: "top",
                timeout: 7500,
                title: "Success",
                text: "Successfully Login",
                visible: true
              };
            })
            .catch((err) => {
              this.loading = false
              this.$store.dispatch('setOverlay', false)
              this.snackbar = {
                color: "error",
                icon: "mdi-alert-circle",
                mode: "multi-line",
                position: "top",
                timeout: 7500,
                title: "Error",
                text: err.response.data.message,
                visible: true
              };
            });
          } else {
            this.snackbar = {
                color: "error",
                icon: "mdi-alert-circle",
                mode: "multi-line",
                position: "top",
                timeout: 7500,
                title: "Error",
                text: 'Please fill NIK and your password and recaptcha',
                visible: true
              };
              return false
          }
          
        },
        absencePermit(){

          this.$router.push('/absencePermit')

        }
    }
}
</script>